import React from 'react'
import logo from "assets/images/logo.png"
import SearchIcon from 'assets/icons/SearchIcon'
import RefreshButton from 'components/button/RefreshButton'

function Navbar() {
   return (
      <div className={`sticky top-0 bg-white shadow-md transition-all z-30`}>
         <div className={`app-nav-container flex justify-between items-center gap-6 md:text-xs lg:text-base`}>
            <div>
               <a href='/'>
                  <img src={logo} alt="logo" />
               </a>
            </div>
            <div>
               <RefreshButton />
            </div>
            <button
               className='text-black text-sm md:hidden active:border-b-2 active:border-white transition-all font-medium'
            >
               <SearchIcon />
            </button>
         </div>
      </div>
   )
}

export default Navbar