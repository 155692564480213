export const API_BASE_URL = process.env.REACT_APP_API_URL
export const CUSTOMER_SITE_URL = process.env.REACT_APP_CUSTOMER_URL

export const GET_ALL_MENU = {
   method: 'post',
   url: "/menu"
}

export const ADD_BILL_DETAIL = {
   method: 'post',
   url: "/bulk-add-bill-detail"
}

export const GET_BILL = {
   method: 'post',
   url: "/get-bill-detail"
}
export const CLOSE_BILL = {
   method: 'post',
   url: "/close-bill"
}

export const PAID_BILL = {
   method: 'post',
   url: "/paid-bill"
}

export const GET_TABLE = {
   method: 'post',
   url: "/get-all-table"
}

export const ADD_BILL = {
   method: 'post',
   url: "/add-bill"
}
export const UPDATE_BILL = {
   method: 'post',
   url: "/update-bill"
}
export const GET_ALL_WAITING_LIST = {
   method: 'post',
   url: "/get-all-waiting-list"
}
export const GET_WAITING_LIST_DETAIL = {
   method: 'post',
   url: "/get-waiting-list-dtl"
}
export const DELETE_WAITING_LIST = {
   method: 'post',
   url: "/delete-waiting-list"
}
export const ADD_WAITING_LIST = {
   method: 'post',
   url: "/add-waiting-list"
}
export const GET_ALL_ORDER_KITCHEN = {
   method: 'post',
   url: "/get-bill-all-detail"
}

export const UPDATE_BILL_DETAIL = {
   method: 'post',
   url: "/update-bill-detail"
}

export const PRINT_BILL_DETAIL = {
   method: 'post',
   url: "/get-bill-detail-by-cd-print"
}

export const DELETE_BILL_DETAIL_BULK = {
   method: "post",
   url: "/delete-bill-detail-bulk"
}