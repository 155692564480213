import RefreshIcon from 'assets/icons/RefreshIcon'
import React, { useState } from 'react'

function RefreshButton() {
   const [animate, setAnimate] = useState(false)
   const handleRefresh = () => {
      setAnimate(true)
      window.location.reload()
   }

   return (
      <button className={`${animate ? "animate-spin" : ""}`} onClick={handleRefresh}>
         <RefreshIcon />
      </button>
   )
}

export default RefreshButton