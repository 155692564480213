import React from 'react'

function RefreshIcon({
   className = "w-6 h-6 fill-app-matte-black"
}) {
   return (
      <svg className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
         <g clipPath="url(#clip0_6_13405)">
            <path d="M17.6498 6.35C16.1998 4.9 14.2098 4 11.9998 4C7.57977 4 4.00977 7.58 4.00977 12C4.00977 16.42 7.57977 20 11.9998 20C15.7298 20 18.8398 17.45 19.7298 14H17.6498C16.8298 16.33 14.6098 18 11.9998 18C8.68977 18 5.99977 15.31 5.99977 12C5.99977 8.69 8.68977 6 11.9998 6C13.6598 6 15.1398 6.69 16.2198 7.78L12.9998 11H19.9998V4L17.6498 6.35Z" />
         </g>
         <defs>
            <clipPath id="clip0_6_13405">
               <rect width="24" height="24" fill="white" />
            </clipPath>
         </defs>
      </svg>
   )
}

export default RefreshIcon