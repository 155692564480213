import { useCallback, useState } from 'react'
import api from 'api'

function useFetch(request) {
   const [data, setData] = useState(null)
   const [loading, setLoading] = useState(true)
   const [error, setError] = useState(null)

   const fetch = useCallback(async ({ query, params, data, headers } = {}) => {
      try {
         setLoading(true)
         const paramKeys = params ? Object.keys(params) : [];
         const response = await api.request({
            method: request.method,
            url: params ? request.url.replace(`:${paramKeys[0]}`, params[paramKeys[0]]) : request.url,
            params: query || undefined,
            data: data || undefined,
            headers: {
               'Content-Type': 'application/json',
               ...headers,
            },
         })
         setData(response.data)
         return response.data
      } catch (error) {
         setError(error)
      } finally {
         setLoading(false)
      }
   }, [request])

   return {
      data,
      loading,
      error,
      fetch,
   }
}

export default useFetch