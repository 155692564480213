import React from 'react'

function Sidebar({
   isOpen,
   onClose,
}) {
   return (
      <div className={`md:hidden fixed top-0 bottom-0 left-0 right-0 bg-white/50 backdrop-blur-lg ${isOpen ? "inline-block" : "hidden"} z-50`}>
         <div className='app-nav-container h-full flex flex-col gap-8'>
            <div className='text-right font-medium'>
               <button className='text-sm active:border-b-2 active:border-black transition-all' onClick={onClose}>Close</button>
            </div>
            <div className={`flex flex-col justify-between flex-1`}>
               <ul className='flex flex-col gap-2 font-medium'>
                  <li>
                     <a href="/">Home</a>
                  </li>
                  <li>
                     <a href="/menu">Menu</a>
                  </li>
               </ul>
               <div className='flex items-center gap-2 border border-black rounded-md px-3 py-2'>
                  <input
                     className='flex-1 bg-transparent outline-none text-sm text-black placeholder-black/50'
                     placeholder='Search for anything'
                  />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Sidebar