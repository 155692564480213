import React, { useState } from 'react'

import Navbar from './Navbar'
import Sidebar from './Sidebar'

function MainContainer({
   children
}) {
   const [isMenuOpen, setMenuOpen] = useState(false)

   return (
      <div className='pb-24'>
         <Navbar
            openMenu={() => setMenuOpen(true)}
         />
         {children}
         <Sidebar
            isOpen={isMenuOpen}
            onClose={() => setMenuOpen(false)}
         />
      </div>
   )
}

export default MainContainer